import { Fragment, lazy, Suspense, useContext, useEffect, useState } from 'react';
import { APButton, APIcon } from '@ap/design-system';
import useCurrentUserCan from '../../../users/services/useCurrentUserCan';
import { UserPermission } from '../../../users/enums/UserPermission';
import { ReportingContext, ReportsFilters } from '../../pages/Reporting';
import { StyledStack } from './styles';
import useBreakpointDown from '../../../../template/hooks/useBreakpointDown';

const CalendarDialogReports = lazy(() => import('../CalendarReportsDialog/CalendarReportsDialog'));

function ReportingCommands() {
  const { filters } = useContext(ReportingContext);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const canReport = useCurrentUserCan(UserPermission.REPORTING);

  const isMobile = useBreakpointDown('md');

  const handleClose = async () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    let disabled = true;

    disabled = filters.find((e) => e.id === ReportsFilters.BOUTIQUES)?.value.length === 0;

    setDisabled(disabled);
  }, [filters]);

  if (!canReport) {
    return null;
  }

  return (
    <Fragment>
      <StyledStack direction='row' alignItems='center' spacing={3}>
        <APButton
          startIcon={<APIcon name='Analytics' fontSize='small' />}
          onClick={() => setDialogOpen(true)}
          disabled={disabled}
        >
          {isMobile ? 'Generate' : 'Generate reports'}
        </APButton>
      </StyledStack>
      <Suspense fallback={<Fragment />}>{dialogOpen && <CalendarDialogReports onClose={handleClose} />}</Suspense>
    </Fragment>
  );
}

export default ReportingCommands;
