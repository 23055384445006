import { useLazyQuery } from '@apollo/client';
import { GET_BOOKING_SETTINGS } from './query';
import BookingSetting, { BookingSettingQueryInput } from '../models/BookingSetting';

interface QueryData {
  bookingSetting: BookingSetting;
}

interface QueryVars {
  query: Pick<BookingSettingQueryInput, 'bookingId'>;
}
/**
 * Fetch all settings of the given booking
 */
function useFetchBookingSettings() {
  const [execute, { loading, error, data, refetch, called }] = useLazyQuery<QueryData, QueryVars>(
    GET_BOOKING_SETTINGS,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const fetchBookingSettings = async (bookingId: string) => {
    const query: QueryVars = {
      query: {
        bookingId: {
          _id: bookingId,
        },
      },
    };

    const response = called
      ? await refetch(query)
      : await execute({
          variables: query,
        });

    return response.data?.bookingSetting;
  };

  return { fetchBookingSettings, loading, error, settings: data?.bookingSetting };
}

export default useFetchBookingSettings;
