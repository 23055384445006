import { useLazyQuery } from '@apollo/client';
import { GET_BOOKING_STAFF_MEMBERS } from './query';
import SortBy from '../../../models/SortBy';
import BookingStaff, { BookingStaffQueryInput } from '../models/BookingStaff';
import useFetchPeople from '../../users/services/useFetchPeople';
import { useState } from 'react';

interface QueryData {
  bookingStaffs: BookingStaff[];
}

interface QueryVars {
  sortBy: string;
  query: Pick<BookingStaffQueryInput, 'bookingId'>;
}

/**
 * Fetch all staff members of the given booking
 */
function useFetchBookingStaffMembers() {
  const { fetchPeople } = useFetchPeople();

  const [execute, { loading, error, data, refetch, called }] = useLazyQuery<QueryData, QueryVars>(
    GET_BOOKING_STAFF_MEMBERS,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const [staffMembers, setStaffMembers] = useState<BookingStaff[]>([]);

  const formatData = async (data?: BookingStaff[]): Promise<BookingStaff[]> => {
    const people = await fetchPeople((data ?? []).map((staffMember) => staffMember.externalUserId));

    return (data ?? []).map((staffMember) => {
      return {
        ...staffMember,
        user: people.find((person) => person.externalUserId === staffMember.externalUserId),
      };
    });
  };

  const fetchBookingStaffMembers = async (bookingId: string, sortBy?: SortBy) => {
    const sortField = sortBy?.field.split('.')[0].toUpperCase() || '_ID';
    const sortDirection = sortBy?.sort.toUpperCase() || 'ASC';
    const sortProperty = `${sortField}_${sortDirection}`;

    const query: QueryVars = {
      query: {
        bookingId: {
          _id: bookingId,
        },
      },
      sortBy: sortProperty,
    };

    const response = called
      ? await refetch(query)
      : await execute({
          variables: query,
        });

    const items = await formatData(response.data?.bookingStaffs);
    setStaffMembers(items);

    return items;
  };

  return { fetchBookingStaffMembers, loading, error, staffMembers };
}

export default useFetchBookingStaffMembers;
