import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { tokens } from '@ap/design-system-ts-tokens';

export const StyledBoutiquePicker = styled(Box)(({ theme }) => {
  const mobileBreakpoint = theme.breakpoints.down('md');

  const {
    spacing: { gapforced },
  } = tokens.density.standard.ref;

  return {
    '& > .MuiBox-root': {
      padding: `0px ${gapforced.xl}px`,
    },
    width: '100%',
    [mobileBreakpoint]: {
      '& > .MuiBox-root': {
        padding: 0,
      },
    },
  };
});
