import { JSX } from 'react';
import { StyledFooter } from './styles';

interface Props {
  dialogActions?: JSX.Element;
}

function BookingFooterActions(props: Props) {
  const { dialogActions } = props;

  if (!dialogActions) {
    return null;
  }

  return <StyledFooter>{dialogActions}</StyledFooter>;
}

export default BookingFooterActions;
