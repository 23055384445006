import BaseModel from '../../../models/BaseModel';
import BaseQueryInputModel from '../../../models/BaseQueryInputModel';

interface BoutiqueDailyHours {
  iso: number;
  openAt: string;
  closeAt: string;
  isClosed: boolean;
}

interface GeocodedCoordinate {
  latitude: number;
  longitude: number;
}

interface Address {
  address: string;
  city: string;
  countryCode?: string;
  line1: string;
  line2: string;
  postalCode: string;
  region: string;
  sublocality: string;
}

export enum BoutiqueType {
  Boutique = 'AP_BOUTIQUE',
  House = 'AP_HOUSE',
}

export enum BoutiqueOwner {
  JointVenture = 'JOINT_VENTURE',
  Internal = 'INTERNAL',
}

export default interface MasterDataBoutique extends BaseModel {
  address: Address;
  bookingEnabled: boolean;
  boutiqueId?: string;
  email: string;
  gpsCoordinates: GeocodedCoordinate;
  hours?: BoutiqueDailyHours[];
  isVisible: boolean;
  mainPhone: string;
  name: string;
  owner: BoutiqueOwner;
  photoUrl: string;
  serviceCenter: boolean;
  timezone: string;
  type: BoutiqueType;
}

// MongoDB Realm GraphQL resolvers

export interface MasterDataBoutiqueInsertInput extends MasterDataBoutique {}

export interface MasterDataBoutiqueQueryInput extends BaseQueryInputModel {
  AND?: MasterDataBoutiqueQueryInput[];
  OR?: MasterDataBoutiqueQueryInput[];
  boutiqueId?: string;
  boutiqueId_exists?: boolean;
  boutiqueId_gt?: string;
  boutiqueId_gte?: string;
  boutiqueId_in?: string[];
  boutiqueId_lt?: string;
  boutiqueId_lte?: string;
  boutiqueId_ne?: string;
  boutiqueId_nin?: string[];
  name?: string;
  name_exists?: boolean;
  name_gt?: string;
  name_gte?: string;
  name_in?: string[];
  name_lt?: string;
  name_lte?: string;
  name_ne?: string;
  name_nin?: string[];
  type?: string;
  type_exists?: boolean;
  type_gt?: string;
  type_gte?: string;
  type_in?: string[];
  type_lt?: string;
  type_lte?: string;
  type_ne?: string;
  type_nin?: string[];
}
