import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { getThemeAdaptedTokens } from '@ap/design-system/dist/helpers';
import { tokens } from '@ap/design-system-ts-tokens';

export const StyledFooter = styled(Box)(({ theme }) => {
  const { fd } = getThemeAdaptedTokens(theme);
  const mobileBreakpoint = theme.breakpoints.down('md');

  return {
    position: 'sticky',
    width: '100%',
    bottom: 0,
    padding: `${tokens.density.standard.ref.spacing.gapforced.xl}px ${tokens.density.standard.ref.spacing.gapforced.x5l}px`,
    borderTop: `0.5px solid ${fd.stroke.neutral}`,
    backgroundColor: theme.palette.background.paper,
    [mobileBreakpoint]: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: `${tokens.density.standard.ref.spacing.gapforced.l}px ${tokens.density.standard.ref.spacing.gapforced.xl}px`,
    },
  };
});
