import { lazy } from 'react';
import { RouteObject } from 'react-router';
import {
  URI_BOOKING_CALENDAR,
  URI_BOOKING_CATEGORIES,
  URI_BOOKING_SETTINGS,
  URI_BOOKING_STAFF,
  URI_BOOKING_TEMPLATES,
} from '../../booking/router/uri';
import { URI_BOUTIQUE_BASE, URI_BOUTIQUE_CLIENTS, URI_BOUTIQUES } from './uri';
import { UserPermission } from '../../users/enums/UserPermission';

const Boutique = lazy(() => import('../pages/Boutique'));
const Boutiques = lazy(() => import('../pages/Boutiques'));
const Overview = lazy(() => import('../pages/Overview'));
const BookingCalendar = lazy(() => import('../../booking/pages/BookingCalendar'));
const Guests = lazy(() => import('../../guests/pages/Guests'));
const BookingStaff = lazy(() => import('../../booking/pages/BookingStaff'));
const BookingCategories = lazy(() => import('../../booking/pages/BookingCategories'));
const BookingTemplates = lazy(() => import('../../booking/pages/BookingTemplates'));
const BookingSettings = lazy(() => import('../../booking/pages/BookingSettings'));

const routes = (userCan: (permission: UserPermission) => boolean): RouteObject[] => {
  return [
    ...(userCan(UserPermission.BOUTIQUES_LISTING)
      ? [
          {
            path: URI_BOUTIQUES,
            element: <Boutiques />,
          },
          {
            path: URI_BOUTIQUE_BASE,
            element: <Boutique />,
            children: [
              {
                index: true,
                element: <Overview />,
              },
              {
                path: URI_BOOKING_CALENDAR,
                element: <BookingCalendar />,
              },
              {
                path: URI_BOUTIQUE_CLIENTS,
                element: <Guests />,
              },
              {
                path: URI_BOOKING_STAFF,
                element: <BookingStaff />,
              },
              {
                path: URI_BOOKING_CATEGORIES,
                element: <BookingCategories />,
              },
              {
                path: URI_BOOKING_TEMPLATES,
                element: <BookingTemplates />,
              },
              {
                path: URI_BOOKING_SETTINGS,
                element: <BookingSettings />,
              },
            ],
          },
        ]
      : []),
  ];
};

export default routes;
