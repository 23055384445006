import { useLazyQuery } from '@apollo/client';
import { GET_BOOKING } from './query';
import Booking, { BookingQueryInput } from '../models/Booking';
import { useParams } from 'react-router-dom';

interface QueryData {
  booking: Booking;
}

interface QueryVariables {
  query: Pick<BookingQueryInput, '_partition'>;
}

/**
 * Fetch a specific booking
 */
function useFetchBooking() {
  const { boutiqueId } = useParams();

  const [execute, { loading, error, data }] = useLazyQuery<QueryData, QueryVariables>(GET_BOOKING, {
    notifyOnNetworkStatusChange: true,
  });

  const fetchBooking = async (): Promise<Booking | undefined> => {
    const query = {
      query: {
        _partition: boutiqueId,
      },
    };

    const response = await execute({
      variables: query,
    });

    return response.data?.booking;
  };

  return { fetchBooking, loading, error, data: data?.booking };
}

export default useFetchBooking;
