import { useState } from 'react';
import axios from 'axios';
import useGetMsalAccessToken from './useGetMsalAccessToken';

/**
 * Get the profile thumbnail image for a given user
 */
function useGetActiveDirectoryUserThumbnail() {
  const [loading, setLoading] = useState(false);
  const { getAccessToken } = useGetMsalAccessToken();

  /**
   * Get an Active Directory user's image
   *
   * @param {string} userId The user's ID
   * @param {boolean} blobAsUrl Set to FALSE to return the Blob object, or TRUE to return a URL
   * @return {Blob|string|undefined}
   */
  const getUserThumbnail = async (userId: string, blobAsUrl: boolean = true): Promise<Blob | string | undefined> => {
    setLoading(true);

    try {
      const accessToken = await getAccessToken();
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const url = `${import.meta.env.VITE_API_URL}/users/${userId}/thumbnail`;
      const result = await axios.get(url, { headers, responseType: 'blob' });

      if (result.status !== 200) {
        return undefined;
      }

      return blobAsUrl && result?.data instanceof Blob ? URL.createObjectURL(result?.data) : result?.data;
    } catch (err) {
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  return { getUserThumbnail, loading };
}

export default useGetActiveDirectoryUserThumbnail;
