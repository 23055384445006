export enum UserPermission {
  BLOG_READ = 'blogRead',
  BLOG_CREATE = 'blogCreate',
  BLOG_UPDATE = 'blogUpdate',
  BLOG_DELETE = 'blogDelete',

  USERS_MANAGE = 'usersManage',

  REPORTING = 'reporting',

  BOUTIQUES_LISTING = 'boutiquesListing',
  BOUTIQUE_UPDATE = 'boutiqueUpdate',
  BOUTIQUE_DELETE = 'boutiqueDelete',

  BOUTIQUE_WIDGET_CREATE = 'boutiqueWidgetCreate',
  BOUTIQUE_WIDGET_UPDATE = 'boutiqueWidgetUpdate',
  BOUTIQUE_WIDGET_DELETE = 'boutiqueWidgetDelete',

  BOUTIQUE_GUEST_READ = 'boutiqueGuestRead',
  BOUTIQUE_GUEST_CREATE = 'boutiqueGuestCreate',
  BOUTIQUE_GUEST_UPDATE = 'boutiqueGuestUpdate',
  BOUTIQUE_GUEST_DELETE = 'boutiqueGuestDelete',
  BOUTIQUE_GUEST_EXPORT = 'boutiqueGuestExport',

  BOOKING_UPDATE = 'bookingUpdate',

  BOOKING_CALENDAR_MASTER_READ = 'bookingCalendarMasterRead',
  BOOKING_CALENDAR_OTHER_READ = 'bookingCalendarOtherRead',
  BOOKING_CALENDAR_BOOKING_ASSIGN = 'bookingCalendarBookingAssign',
  BOOKING_CALENDAR_BOOKING_CREATE = 'bookingCalendarBookingCreate',
  BOOKING_CALENDAR_OWN_BOOKING_UPDATE = 'bookingCalendarOwnBookingUpdate',
  BOOKING_CALENDAR_OWN_BOOKING_DELETE = 'bookingCalendarOwnBookingDelete',
  BOOKING_CALENDAR_OTHER_BOOKING_UPDATE = 'bookingCalendarOtherBookingUpdate',
  BOOKING_CALENDAR_OTHER_BOOKING_DELETE = 'bookingCalendarOtherBookingDelete',
  BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL = 'bookingCalendarResendConfirmationEmail',
  BOOKING_CALENDAR_OWN_OUTLOOK_EVENT_UPSERT = 'bookingCalendarOwnOutlookEventUpsert',

  BOOKING_SETTINGS_READ = 'bookingSettingsRead',
  BOOKING_SETTINGS_UPDATE = 'bookingSettingsUpdate',
  BOOKING_SETTINGS_UPDATE_DISALLOW_GUEST_CHANGE_BOOKING = 'bookingSettingsUpdateDisallowGuestChangeBooking',
  BOOKING_SETTINGS_UPDATE_DISALLOW_GUEST_CANCEL_BOOKING = 'bookingSettingsUpdateDisallowGuestCancelBooking',
  BOOKING_SETTINGS_UPDATE_ALLOW_QR_CODE = 'bookingSettingsUpdateAllowQrCode',
  BOOKING_SETTINGS_UPDATE_ENABLE_GOOGLE_WALLET = 'bookingSettingsUpdateEnableGoogleWallet',
  BOOKING_SETTINGS_MANAGE_LANGUAGES = 'bookingSettingsManageLanguages',
  BOOKING_SETTINGS_ADVANCED_SCHEDULING = 'bookingSettingsAdvancedScheduling',
  BOOKING_SETTINGS_ADVANCED_FEATURES = 'bookingSettingsAdvancedFeatures',

  BOOKING_TEMPLATE_READ = 'bookingTemplateRead',
  BOOKING_TEMPLATE_UPDATE = 'bookingTemplateUpdate',

  BOOKING_CATEGORIES_READ = 'bookingCategoriesRead',
  BOOKING_CATEGORIES_CREATE = 'bookingCategoriesCreate',
  BOOKING_CATEGORIES_UPDATE = 'bookingCategoriesUpdate',
  BOOKING_CATEGORIES_DELETE = 'bookingCategoriesDelete',

  BOOKING_STAFF_READ = 'bookingStaffRead',
  BOOKING_STAFF_UPDATE = 'bookingStaffUpdate',
  BOOKING_STAFF_UPDATE_NOTIFY = 'bookingStaffUpdateNotify',
}
