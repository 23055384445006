import { createContext, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PageContainer from '../../../template/components/PageContainer/PageContainer';
import useSetBreadcrumbItems from '../../../template/components/Breadcrumb/useSetBreadcrumbItems';
import CalendarSelectionTable from '../components/CalendarSelectionTable/CalendarSelectionTable';
import { StyledContent } from '../../../template/components/Content/styles';
import ReportingCommands from '../components/ReportingCommands/ReportingCommands';
import { LayoutContext } from '../../../template/components/Layout/Layout';
import useBreakpointDown from '../../../template/hooks/useBreakpointDown';

const pageTitle = 'Calendar reporting';

export enum ReportsFilters {
  BOUTIQUES = 'boutiques',
}

export type IReportsFilter = {
  id: ReportsFilters;
  value: any;
};

const defaultReportsFilters: IReportsFilter[] = [{ id: ReportsFilters.BOUTIQUES, value: [] }];

interface ReportingContextInterface {
  filters: IReportsFilter[];
  setFilters: (filters: IReportsFilter[]) => void;
}

export const ReportingContext = createContext<ReportingContextInterface>({
  filters: [],
  setFilters: () => {},
});

function Reporting() {
  useSetBreadcrumbItems([{ label: pageTitle }]);
  const { setContainerSx } = useContext(LayoutContext);
  const isMobile = useBreakpointDown('md');

  const [filters, setFilters] = useState<IReportsFilter[]>(defaultReportsFilters);

  useEffect(() => {
    setContainerSx({
      paddingBottom: isMobile ? '72px !important' : '10px !important',
    });

    return () => {
      setContainerSx({});
    };
  }, [setContainerSx, isMobile]);

  return (
    <ReportingContext.Provider value={{ filters, setFilters }}>
      <PageContainer pageTitle={pageTitle}>
        <StyledContent>
          <Box>
            <Typography variant='displayTitle'>{pageTitle}</Typography>
            <Typography variant='subheadline'>Generate reports of boutiques and AP Houses</Typography>
          </Box>
          <ReportingCommands />
        </StyledContent>
        <CalendarSelectionTable />
      </PageContainer>
    </ReportingContext.Provider>
  );
}

export default Reporting;
