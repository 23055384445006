import { useCallback, useContext, useEffect, useState } from 'react';
import {
  GridRowId,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortModel,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid-premium';
import Stack from '@mui/material/Stack';
import { GridGroupingColDefOverride } from '@mui/x-data-grid-pro/models/gridGroupingColDefOverride';
import useFetchCountries from '../../../countries/services/useFetchCountries';
import { APBanner, APDataGrid, APFlag } from '@ap/design-system';
import { APFlagCountryCode } from '@ap/design-system/dist/components/APFlag/types';
import { APGridColDef } from '@ap/design-system/dist/components/APDataGrid/types';
import { ReportingContext, ReportsFilters } from '../../pages/Reporting';
import useFetchBoutiques from '../../../boutiques/services/boutiques/useFetchBoutiques';
import useBreakpointDown from '../../../../template/hooks/useBreakpointDown';

const defaultCurrentPage = 0; // Starts at 0
const defaultPageSize = 25;
const defaultSortModel: GridSortModel = [
  {
    field: 'name',
    sort: 'asc',
  },
];

const initialColumns: APGridColDef[] = [
  {
    field: 'country',
    headerName: 'Country',
    sortable: true,
    filterable: true,
    width: 110,
    align: 'center',
    type: 'singleSelect',
    valueOptions: [],
  },
  { field: 'name', headerName: 'Name', flex: 2, hideable: false },
];

function CalendarSelectionTable() {
  const { filters, setFilters } = useContext(ReportingContext);

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [columns, setColumns] = useState(initialColumns);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  const { fetchBoutiques, boutiques, loading, error, pagination } = useFetchBoutiques();
  const { fetchCountries } = useFetchCountries();

  const isMobile = useBreakpointDown('md');

  useEffect(() => {
    const boutiqueRows = [
      ...boutiques.map((boutique) => ({
        path: [boutique._id],
        id: boutique._id,
        country: boutique.country?._id,
        name: boutique.name,
      })),
    ];

    setRows([...boutiqueRows]);
  }, [boutiques]);

  useEffect(() => {
    const initColumnsFilters = async () => {
      const countries = await fetchCountries();

      setColumns((prevColumns) =>
        prevColumns.map((column) => {
          if (column.field === 'country') {
            return {
              ...column,
              valueOptions: countries
                .map((country) => ({
                  value: country._id,
                  label: country.name,
                }))
                .sort((c1, c2) => c1.label.localeCompare(c2.label)),
              renderCell: (params) => {
                const country = countries.find((country) => country._id === params.value);

                return country ? (
                  <APFlag
                    countryCode={country._id.toLowerCase() as APFlagCountryCode}
                    countryName={country.name}
                    width={20}
                    height={20}
                  />
                ) : (
                  ''
                );
              },
              sortComparator: (value1, value2, param1, param2) => {
                const country1 = countries.find((country) => country._id === value1);
                const country2 = countries.find((country) => country._id === value2);

                return gridStringOrNumberComparator(country1?.name ?? value1, country2?.name ?? value2, param1, param2);
              },
            } as APGridColDef;
          }

          return column;
        }),
      );
    };

    initColumnsFilters();
  }, []);

  const handleReload = useCallback(async () => {
    const paramSortModel = sortModel.length > 0 ? sortModel[0] : defaultSortModel[0];

    await fetchBoutiques({
      limit: pageSize,
      offset: currentPage === 0 ? 0 : currentPage * pageSize + 1,
      sortBy: paramSortModel.field,
      sortDirection: paramSortModel.sort as string,
    });
  }, [pageSize, currentPage, sortModel, filters]);

  /**
   * Fetch the boutiques:
   * - on initial page load
   * - on pagination changes (rows per page option, current page)
   * - on sort changes
   * - on search
   */
  useEffect(() => {
    if (!loading) {
      handleReload();
    }
  }, [currentPage, pageSize, sortModel, filters]);

  const groupingColDef: GridGroupingColDefOverride = {
    headerName: '',
    hideDescendantCount: true,
    valueFormatter: () => '',
    width: 20,
  };

  return (
    <Stack
      direction='column'
      spacing={4}
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
      }}
    >
      {error && !loading && (
        <APBanner severity='danger' showIcon>
          An error occurred while fetching the boutiques and AP houses
        </APBanner>
      )}
      <APDataGrid
        groupingColDef={groupingColDef}
        autoHeight
        rows={rows}
        columns={columns}
        sortingMode='server'
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        checkboxSelection
        rowSelection
        localeText={{
          noRowsLabel: `No boutiques or AP houses`,
          columnMenuSortAsc: 'Sort ascending',
          columnMenuSortDesc: 'Sort descending',
          columnMenuHideColumn: 'Hide column',
        }}
        loading={loading}
        rowSelectionModel={selectionModel}
        keepNonExistentRowsSelected
        onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
          // Remove selection for current page
          const boutiquesFilter = filters.find((e) => e.id === ReportsFilters.BOUTIQUES);
          if (boutiquesFilter) {
            for (const boutiqueId of boutiquesFilter.value) {
              if (boutiques.some((e) => e._id === boutiqueId)) {
                boutiquesFilter.value = boutiquesFilter.value.filter((e: string) => e !== boutiqueId);
              }
            }
          }
          // Push new selection for current page
          for (const id of newSelectionModel) {
            // Push boutique to filters
            if (boutiques.find((e) => e._id === id)) {
              boutiquesFilter?.value.push(id as string);
            }
          }
          setFilters([...filters]);
          setSelectionModel(newSelectionModel);
        }}
        pagination
        paginationModel={{ page: currentPage, pageSize }}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationMode='server'
        simplePagination={isMobile}
        onPaginationModelChange={(model) => {
          setPageSize(model.pageSize);
          setCurrentPage(model.page);
        }}
        rowCount={pagination.totalItems}
        disableColumnMenu={isMobile}
        sx={{
          '& .MuiDataGrid-footerContainer': {
            paddingTop: 2,
            borderTop: 'none',
          },
        }}
      />
    </Stack>
  );
}

export default CalendarSelectionTable;
