import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { APCommonPageLayout, APIcon, APLink, APNavigationLogo, AppLogoAp } from '@ap/design-system';
import { APNavigationProps } from '@ap/design-system/dist/components/APNavigation/types';
import AppRouter from '../../../config/router/AppRouter';
import BackToTop from '../BackToTop/BackToTop';
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem';
import { AppContext } from '../../../bootstrap/App';
import BlogInfoPopup from '../../../features/blog/components/BlogInfoPopup/BlogInfoPopup';
import WelcomeWizard from '../WelcomeWizard/WelcomeWizard';
import useCurrentUserCan from '../../../features/users/services/useCurrentUserCan';
import { URI_BOUTIQUES, URI_BOUTIQUE, URI_BOUTIQUE_CLIENTS } from '../../../features/boutiques/router/uri';
import { UserPermission } from '../../../features/users/enums/UserPermission';
import { useLocation, useNavigate } from 'react-router-dom';
import { URI_BLOG } from '../../../features/blog/router/uri';
import { URI_USERS } from '../../../features/users/router/uri';
import Account from '../../../features/users/components/Account/Account';
import SettingsDialog from '../SettingsDialog/SettingsDialog';
import useSetCurrentUserData from '../../../features/users/services/useSetCurrentUserData';
import { UserTheme } from '../../../features/users/enums/UserTheme';
import DialogEasterEgg from '../DialogEasterEgg/DialogEasterEgg';
import useFetchNotifications from '../../../features/notification/services/useFetchNotifications';
import useGetCurrentUser from '../../../features/users/services/useGetCurrentUser';
import NotificationDrawer from '../../../features/notification/components/NotificationDrawer/NotificationDrawer';
import { REPORTING } from '../../../features/reporting/router/uri';
import useBreakpointDown from '../../hooks/useBreakpointDown';
import BoutiquePicker from '../../../features/boutiques/components/BoutiquePicker/BoutiquePicker';
import { StyledBoutiquePicker } from './styles';
import Boutique from '../../../features/boutiques/models/Boutique';
import {
  URI_BOOKING_CALENDAR,
  URI_BOOKING_CATEGORIES,
  URI_BOOKING_SETTINGS,
  URI_BOOKING_STAFF,
  URI_BOOKING_TEMPLATES,
} from '../../../features/booking/router/uri';

const basePageTitle = 'AP Calendar';
const allBoutiques = { _id: 'all', name: 'All boutiques' };

interface LayoutContext {
  breadcrumbItems: BreadcrumbItem[];
  setBreadcrumbItems: (breadcrumbItems: BreadcrumbItem[]) => void;
  breadcrumbVisible: boolean;
  setBreadcrumbVisible: (visible: boolean) => void;
  containerSx: SxProps;
  setContainerSx: (sx: SxProps) => void;
  headerExtraContent?: React.ReactNode;
  setHeaderExtraContent: (children?: React.ReactNode) => void;
  boutique: string | undefined;
  setBoutique: (boutique: string) => void;
}

export const LayoutContext = createContext<LayoutContext>({
  breadcrumbItems: [],
  setBreadcrumbItems: () => {},
  breadcrumbVisible: true,
  setBreadcrumbVisible: () => {},
  containerSx: {},
  setContainerSx: () => {},
  headerExtraContent: undefined,
  setHeaderExtraContent: () => {},
  boutique: undefined,
  setBoutique: () => {},
});

function Layout() {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const [breadcrumbItems, setBreadcrumbItems] = useState<Array<any>>([]);
  const [breadcrumbVisible, setBreadcrumbVisible] = useState(true);
  const [containerSx, setContainerSx] = useState<SxProps>({});
  const [headerExtraContent, setHeaderExtraContent] = useState<React.ReactNode | undefined>(undefined);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [countClick, setCountClick] = useState(0);
  const [eggOpen, setEggOpen] = useState(false);
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);

  const currentUser = useGetCurrentUser();
  const userCan = useCurrentUserCan();
  const { setData } = useSetCurrentUserData();

  const [boutique, setBoutique] = useState<string | undefined>(
    currentUser.boutique && currentUser.boutique.trim() ? currentUser.boutique : undefined,
  );

  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useBreakpointDown('md');

  const { fetchNotifications, loading: loadingNotifications, notifications } = useFetchNotifications();

  // On page load fetch the groups notification
  useEffect(() => {
    (async () => {
      if (loadingNotifications) {
        return;
      }

      await fetchNotifications(currentUser.roles);
    })();
  }, [currentUser.roles]);

  const isCurrentRoute = useMemo(
    () => (path: string) => {
      return location?.pathname ? location.pathname === `/${path}` : false;
    },
    [location?.pathname],
  );

  const handleSwitchTheme = async () => {
    const newValue = !darkMode;

    setDarkMode(newValue);

    await setData([{ name: 'theme', value: newValue ? UserTheme.Dark : UserTheme.Light }]);
  };

  const handleClickVersion = () => {
    setCountClick(countClick + 1);
    if (countClick === 9) {
      setCountClick(0);
      setEggOpen(true);
    }
  };

  const handleClickNavigation = (uri: string) => {
    navigate(uri);

    if (isMobile) {
      setMobileMenuExpanded(false);
    }
  };

  const handleBoutiqueNavigation = (uri: string) => {
    handleClickNavigation(`/${URI_BOUTIQUE}/${boutique}${uri ? `/${uri}` : ''}`);
  };

  const toggleNotificationsDrawer = () => {
    setNotificationDrawerOpen(!notificationDrawerOpen);
  };

  const handleChangeBoutique = (value: Boutique) => {
    setBoutique(value._id);

    setData([{ name: 'boutique', value: value._id }]);
    if (value._id === allBoutiques._id) {
      handleClickNavigation(URI_BOUTIQUES);
    } else {
      handleClickNavigation(`/${URI_BOUTIQUE}/${value._id}`);
    }
  };

  const navigationProps: APNavigationProps = useMemo(() => {
    return {
      bottomBarAvatarRender: <Account openSettings={() => setSettingsDialogOpen(true)} />,
      title: 'Calendar',
      expandedMenuLogo: <AppLogoAp />,
      collapsedMenuLogo: 'CAL',
      expandedMenuLogoBackgroundColor: 'ref/palette/blue',
      collapsedMenuLogoBackgroundColor: 'ref/palette/blue',
      collapsedMenuLogoFillColor: 'fd/icn/inversedforced',
      expandedMenuLogoFillColor: 'fd/icn/inversedforced',
      beforeNavigationItems: (
        <StyledBoutiquePicker>
          <Box>
            <BoutiquePicker
              id='boutiques'
              label='Your boutique'
              value={boutique}
              setValue={(value) => handleChangeBoutique(value as Boutique)}
            />
          </Box>
        </StyledBoutiquePicker>
      ),
      bottomBarButtonsProps: [
        {
          icon: <APIcon name={darkMode ? 'LightMode' : 'DarkMode'} filled={false} />,
          key: 'theme',
          tooltipProps: {
            title: `Turn ${darkMode ? 'on' : 'off'} the light`,
            placement: 'top',
          },
          onClick: handleSwitchTheme,
        },
        {
          icon: (
            <Badge badgeContent={notifications.length} color='error'>
              <APIcon name='Notifications' filled={false} />
            </Badge>
          ),
          key: 'notifications',
          tooltipProps: {
            title: 'Toggle notifications panel',
            placement: 'top',
          },
          onClick: toggleNotificationsDrawer,
        },
        {
          icon: <APIcon name='Info' filled={false} />,
          key: 'about',
          tooltipProps: {
            title: (
              <Box
                sx={{
                  display: 'flex',
                  maxWidth: '400px', // Hardcoded in Figma
                  padding: `0px`,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '4px', // Hardcoded in Figma
                }}
              >
                <Box>
                  Version{' '}
                  <APLink type='label' onClick={handleClickVersion}>
                    {import.meta.env.VITE_VERSION.toString()}
                  </APLink>{' '}
                  made with love
                  <br />
                  by the ITCS team in Le Brassus
                </Box>
              </Box>
            ),
            placement: 'top',
          },
        },
      ],
      links: [
        ...(boutique && boutique !== allBoutiques._id && userCan(UserPermission.BOUTIQUES_LISTING)
          ? [
              {
                label: 'Overview',
                icon: <APIcon name='DashboardCustomize' fontSize='large' />,
                menuKey: 'overview',
                onClick: () => handleBoutiqueNavigation(''),
                selected: isCurrentRoute(`${URI_BOUTIQUE}/${boutique}`),
              },
              ...(userCan(UserPermission.BOOKING_CALENDAR_MASTER_READ)
                ? [
                    {
                      label: 'Calendar',
                      icon: <APIcon name='CalendarMonth' fontSize='large' />,
                      menuKey: 'calendar',
                      onClick: () => handleBoutiqueNavigation(URI_BOOKING_CALENDAR),
                      selected: isCurrentRoute(`${URI_BOUTIQUE}/${boutique}/${URI_BOOKING_CALENDAR}`),
                    },
                  ]
                : []),
              ...(userCan(UserPermission.BOUTIQUE_GUEST_READ)
                ? [
                    {
                      label: 'Clients',
                      icon: <APIcon name='Groups' fontSize='large' />,
                      menuKey: 'clients',
                      onClick: () => handleBoutiqueNavigation(URI_BOUTIQUE_CLIENTS),
                      selected: isCurrentRoute(`${URI_BOUTIQUE}/${boutique}/${URI_BOUTIQUE_CLIENTS}`),
                    },
                  ]
                : []),
              ...(userCan(UserPermission.BOOKING_STAFF_READ)
                ? [
                    {
                      label: 'Staff members',
                      icon: <APIcon name='Group' fontSize='large' />,
                      menuKey: 'staff-members',
                      onClick: () => handleBoutiqueNavigation(URI_BOOKING_STAFF),
                      selected: isCurrentRoute(`${URI_BOUTIQUE}/${boutique}/${URI_BOOKING_STAFF}`),
                    },
                  ]
                : []),
              ...(userCan(UserPermission.BOOKING_CATEGORIES_READ)
                ? [
                    {
                      label: 'Categories',
                      icon: <APIcon name='BusinessCenter' fontSize='large' />,
                      menuKey: 'categories',
                      onClick: () => handleBoutiqueNavigation(URI_BOOKING_CATEGORIES),
                      selected: isCurrentRoute(`${URI_BOUTIQUE}/${boutique}/${URI_BOOKING_CATEGORIES}`),
                    },
                  ]
                : []),
              ...(userCan(UserPermission.BOOKING_TEMPLATE_READ)
                ? [
                    {
                      label: 'Templates',
                      icon: <APIcon name='Web' fontSize='large' />,
                      menuKey: 'templates',
                      onClick: () => handleBoutiqueNavigation(URI_BOOKING_TEMPLATES),
                      selected: isCurrentRoute(`${URI_BOUTIQUE}/${boutique}/${URI_BOOKING_TEMPLATES}`),
                    },
                  ]
                : []),
              ...(userCan(UserPermission.BOOKING_SETTINGS_READ)
                ? [
                    {
                      label: 'Settings',
                      icon: <APIcon name='Settings' fontSize='large' />,
                      menuKey: 'settings',
                      onClick: () => handleBoutiqueNavigation(URI_BOOKING_SETTINGS),
                      selected: isCurrentRoute(`${`${URI_BOUTIQUE}/${boutique}/${URI_BOOKING_SETTINGS}`}`),
                    },
                  ]
                : []),
            ]
          : []),
        ...(userCan(UserPermission.REPORTING)
          ? [
              {
                label: 'Reporting',
                icon: <APIcon name='Summarize' fontSize='large' filled={false} />,
                menuKey: 'reporting',
                onClick: () => handleClickNavigation(REPORTING),
                selected: isCurrentRoute(REPORTING),
              },
            ]
          : []),
        ...(userCan(UserPermission.BLOG_READ)
          ? [
              {
                label: 'Blog',
                icon: <APIcon name='Newspaper' fontSize='large' />,
                menuKey: 'blog',
                onClick: () => handleClickNavigation(`/${URI_BLOG}`),
                selected: isCurrentRoute(URI_BLOG),
              },
            ]
          : []),
        ...(userCan(UserPermission.USERS_MANAGE)
          ? [
              {
                label: 'Users',
                icon: <APIcon name='SupervisorAccount' fontSize='large' />,
                menuKey: 'users',
                onClick: () => handleClickNavigation(`/${URI_USERS}`),
                selected: isCurrentRoute(URI_USERS),
              },
            ]
          : []),
      ],
      multiAppSwitcherProps: {
        wifiCode: '',
        appLinks: [
          {
            appLogo: <APNavigationLogo backgroundColor='ref/neutral/whiteForced'>WAP</APNavigationLogo>,
            title: "What's AP",
            onClick: () => (window.location.href = 'https://intranet.audemarspiguet.com/'),
          },
          {
            appLogo: <APNavigationLogo backgroundColor='ref/palette/orange'>AC</APNavigationLogo>,
            title: 'my AP Academy',
            onClick: () => (window.location.href = 'https://audemarspiguet.csod.com/samldefault.aspx'),
          },
        ],
      },
      search: false,
      ...(isMobile && {
        // On mobile device, manually control the expanded state
        // It allows use to close the menu after touching on a link
        expanded: mobileMenuExpanded,
        onMenuExpanded: () => setMobileMenuExpanded(true),
        onMenuCollapsed: () => setMobileMenuExpanded(false),
      }),
    };
  }, [isCurrentRoute, navigate, userCan, notifications.length, darkMode, isMobile]);

  return (
    <LayoutContext.Provider
      value={{
        breadcrumbItems,
        setBreadcrumbItems,
        breadcrumbVisible,
        setBreadcrumbVisible,
        containerSx,
        setContainerSx,
        headerExtraContent,
        setHeaderExtraContent,
        boutique,
        setBoutique,
      }}
    >
      <HelmetProvider>
        <Helmet defaultTitle={basePageTitle} titleTemplate={`${basePageTitle} | %s`} />
        <APCommonPageLayout navbarProps={navigationProps} backgroundColor='fd/bg/container/secondary'>
          <AppRouter />
        </APCommonPageLayout>
        <SettingsDialog open={settingsDialogOpen} onClose={() => setSettingsDialogOpen(false)} />
        <DialogEasterEgg open={eggOpen} onClose={() => setEggOpen(false)} />
        <BackToTop />
        <WelcomeWizard />
        <BlogInfoPopup />
        <ToastContainer
          position='bottom-right'
          autoClose={5000}
          theme={darkMode ? 'dark' : 'light'}
          hideProgressBar
          closeOnClick
          pauseOnHover
          draggable
        />
        <NotificationDrawer
          open={notificationDrawerOpen}
          notifications={notifications}
          onClose={() => setNotificationDrawerOpen(false)}
        />
      </HelmetProvider>
    </LayoutContext.Provider>
  );
}

export default Layout;
