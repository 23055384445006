import React, { createContext, Fragment, lazy, Suspense, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Props as GuestFormProps } from '../../guests/components/GuestsForm/GuestsForm';
import { URI_NOT_FOUND } from '../../errors/router/uri';
import BookingSetting from '../../booking/models/BookingSetting';
import BoutiqueModel from '../models/Boutique';
import ErrorBoundary from '../../errors/components/ErrorBoundary';
import IBooking from '../../booking/models/Booking';
import LoadingSpinner from '../../../template/components/LoadingSpinner/LoadingSpinner';
import PageContainer from '../../../template/components/PageContainer/PageContainer';
import useFetchBoutique from '../services/boutiques/useFetchBoutique';
import { BookingStaffCalendar } from '../../booking/pages/BookingCalendar';
import useGetCurrentUser from '../../users/services/useGetCurrentUser';
import useFetchBookingSettings from '../../booking/services/useFetchBookingSettings';
import useSaveBookingSettings from '../../booking/services/useSaveBookingSettings';
import useFetchBookingStaffMembers from '../../booking/services/useFetchBookingStaffMembers';
import useFetchBooking from '../../booking/services/useFetchBooking';
import IBookingStaff from '../../booking/models/BookingStaff';
import { toast } from 'react-toastify';
import BookingFooterActions from '../../booking/components/BookingFooterActions/BookingFooterActions';

const GuestForm = lazy(() => import('../../guests/components/GuestsForm/GuestsForm'));

const defaultBoutique: BoutiqueModel = {
  _id: '',
  _partition: 'MASTER',
  createdAt: new Date(),
  createdBy: '',
  name: '',
  users: [],
};

interface BoutiqueContextInterface {
  boutique: BoutiqueModel;
  setBoutique: (partial: Partial<BoutiqueModel>) => void;
  onReload: () => void;
  guestFormOpen: boolean;
  setGuestFormOpen: (guestFormOpen: boolean) => void;
  guestFormProps: GuestFormProps;
  setGuestFormProps: React.Dispatch<React.SetStateAction<GuestFormProps>>;
  dialogActions: JSX.Element | undefined;
  setDialogActions: (element?: JSX.Element) => void;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (value: boolean) => void;
  settings: BookingSetting;
  setSettings: (settingsToUpdate: Partial<BookingSetting>) => void;
  booking: IBooking;
  loadingSaveSettings: boolean;
  staffMembers: BookingStaffCalendar[];
  setStaffMembers: React.Dispatch<React.SetStateAction<BookingStaffCalendar[]>>;
  reloadStaffMembers: () => void;
  loadingStaffMembers: boolean;
}

export const BoutiqueContext = createContext<BoutiqueContextInterface>({
  boutique: defaultBoutique,
  setBoutique: () => {},
  onReload: () => {},
  guestFormOpen: false,
  setGuestFormOpen: () => {},
  guestFormProps: { onClose: () => {} },
  setGuestFormProps: () => {},
  dialogActions: <Fragment />,
  setDialogActions: () => {},
  hasUnsavedChanges: false,
  setHasUnsavedChanges: () => {},
  settings: {} as BookingSetting,
  setSettings: () => {},
  booking: {} as IBooking,
  loadingSaveSettings: false,
  staffMembers: [],
  setStaffMembers: () => {},
  reloadStaffMembers: () => {},
  loadingStaffMembers: false,
});

interface Props {
  boutique: BoutiqueModel;
  onReload: () => void;
}

function BoutiqueRender(props: Props) {
  const { boutique, onReload } = props;

  const navigate = useNavigate();

  const currentUser = useGetCurrentUser();

  const [localBoutique, setLocalBoutique] = useState<BoutiqueModel>(boutique);
  const [bookingId, setBookingId] = useState<string | undefined>();

  // The guest form is also part of BoutiqueContext because it's controlled by multiple components:
  // the guests table, additional guests and also the booking
  const [guestFormOpen, setGuestFormOpen] = useState(false);
  const [guestFormProps, setGuestFormProps] = useState<GuestFormProps>({ onClose: () => {} });

  const setBoutique = (partial: Partial<BoutiqueModel>) => setLocalBoutique({ ...localBoutique, ...partial });

  const pageTitle = boutique.name;

  const { fetchBooking, data, loading } = useFetchBooking();
  const { fetchBookingSettings, loading: loadingSettings } = useFetchBookingSettings();
  const { saveSetting, loading: loadingSaveSettings } = useSaveBookingSettings(bookingId ?? '');
  const {
    fetchBookingStaffMembers,
    loading: loadingStaffMembers,
    staffMembers: staffMembersData,
  } = useFetchBookingStaffMembers();

  const [dialogActions, setDialogActions] = useState<JSX.Element | undefined>(<Fragment />);
  const [settings, setSettings] = useState<BookingSetting>();
  const [staffMembers, setStaffMembers] = useState<BookingStaffCalendar[]>([]);

  /**
   * States to track if the user has changed data without saving
   */
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const setStaffMembersWithSelection = (items: IBookingStaff[]) => {
    let userSelectedStaffMembers: string[] = [];
    if (currentUser.bookingCalendar?.selectedStaffMembers) {
      const matchingItem = currentUser.bookingCalendar.selectedStaffMembers.find(
        (item) => item.boutique === boutique._id,
      );
      if (matchingItem) {
        userSelectedStaffMembers = matchingItem.staffMembers;
      }
    } else {
      // The custom user data is not yet initialized (first sign in?)
      // By default, add the current signed-in user to the selection (if he's part of the boutique's staff members)
      const matchingItem = items.find((item) => item.externalUserId === currentUser.externalUserId);
      if (matchingItem) {
        userSelectedStaffMembers = [currentUser.externalUserId];
      }
    }

    setStaffMembers(
      items.map(
        (staffMember) =>
          ({
            ...staffMember,
            selected: userSelectedStaffMembers.includes(staffMember.externalUserId),
          } as BookingStaffCalendar),
      ),
    );
  };

  /**
   * On page load:
   * - fetch booking's data
   * - fetch the settings
   * - fetch the staff members and initialize the selected staff members based on the custom user data
   */
  useEffect(() => {
    (async () => {
      if (!loading) {
        const booking = await fetchBooking();

        setBookingId(booking?._id ?? '');
      }

      if (bookingId && !loadingSettings) {
        await fetchBookingSettings(bookingId ?? '');
        setSettings(await fetchBookingSettings(bookingId ?? ''));
      }

      if (bookingId && !loadingStaffMembers) {
        await fetchBookingStaffMembers(bookingId ?? '');
      }
    })();
  }, [bookingId]);

  useEffect(() => {
    if (loadingStaffMembers) {
      return;
    }

    setStaffMembersWithSelection(staffMembersData);
  }, [staffMembersData]);

  useEffect(() => {
    if (!loading && loadingSettings && !data) {
      navigate(`/${URI_NOT_FOUND}`);
    }
  }, [loading, loadingSettings, data]);

  const handleSetSettings = async (settingsToUpdate: Partial<BookingSetting>) => {
    const updatedSettings = await saveSetting(settingsToUpdate);
    setSettings(updatedSettings);
    toast.success('Settings successfully saved');
    setHasUnsavedChanges(false);
  };

  const reloadStaffMembers = async () => {
    await fetchBookingStaffMembers(bookingId ?? '');
  };

  if (!bookingId || !settings || !staffMembers) {
    return <LoadingSpinner fullPage={true} />;
  }

  return (
    <BoutiqueContext.Provider
      value={{
        boutique: localBoutique,
        setBoutique,
        onReload,
        guestFormOpen,
        setGuestFormOpen,
        guestFormProps,
        setGuestFormProps,
        dialogActions,
        setDialogActions,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        settings: settings as BookingSetting,
        setSettings: handleSetSettings,
        booking: data as IBooking,
        loadingSaveSettings,
        staffMembers,
        setStaffMembers,
        reloadStaffMembers,
        loadingStaffMembers,
      }}
    >
      <PageContainer pageTitle={pageTitle}>
        <Box sx={{ display: 'flex', flex: 1, width: '100%' }}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </PageContainer>
      {/* We need to check if the dialogActions has any props because it's a fragment by default, and we don't want to render it if it's empty */}
      {dialogActions && Object.keys(dialogActions.props).length > 0 && (
        <BookingFooterActions dialogActions={dialogActions} />
      )}
      <Suspense fallback={<Fragment />}>
        {guestFormOpen && <GuestForm key={guestFormProps.guestId} {...guestFormProps} />}
      </Suspense>
    </BoutiqueContext.Provider>
  );
}

function Boutique() {
  const { boutiqueId } = useParams();
  const navigate = useNavigate();

  const { fetchBoutique, boutique, loading, error, isNotFound } = useFetchBoutique();

  const handleFetch = async () => {
    if (boutiqueId) {
      await fetchBoutique(boutiqueId);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [boutiqueId]);

  useEffect(() => {
    if (!boutiqueId && ((!loading && error) || (!loading && isNotFound))) {
      navigate(`/${URI_NOT_FOUND}`);
    }
  }, [boutiqueId, loading, error, isNotFound]);

  if (loading || !boutique) {
    return <LoadingSpinner fullPage />;
  }

  return <BoutiqueRender boutique={boutique} onReload={handleFetch} />;
}

export default Boutique;
