import { RouteObject } from 'react-router';
import { REPORTING } from './uri';
import { UserPermission } from '../../users/enums/UserPermission';
import CalendarReporting from '../pages/Reporting';

const routes = (userCan: (permission: UserPermission) => boolean): RouteObject[] => {
  if (!userCan(UserPermission.REPORTING)) {
    return [];
  }

  return [
    {
      path: REPORTING,
      element: <CalendarReporting />,
    },
  ];
};

export default routes;
