import { useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_BOUTIQUE } from './query';
import { setCountryName } from '../../../countries/services/useFetchCountries';
import Boutique, { BoutiqueQueryInput } from '../../models/Boutique';
import useGetCurrentUser from '../../../users/services/useGetCurrentUser';

interface QueryData {
  boutique: Boutique;
}

interface QueryVariables {
  query: Pick<BoutiqueQueryInput, '_id'>;
}

/**
 * Fetch a specific boutique
 */
function useFetchBoutique() {
  const { language: currentLanguage } = useGetCurrentUser();

  const isNotFound = useRef(false);
  const [execute, { loading, error, data, refetch, called }] = useLazyQuery<QueryData, QueryVariables>(GET_BOUTIQUE, {
    notifyOnNetworkStatusChange: true,
  });

  const formatData = (data?: QueryData): Boutique | undefined => {
    if (!data) {
      return undefined;
    }

    if (!data.boutique) {
      isNotFound.current = true;
      return undefined;
    }

    // We fetch the country name depending on the current user language
    const setLocaleCountryName = setCountryName(currentLanguage);

    const { country: country } = data.boutique;
    return {
      ...data.boutique,
      country: country ? setLocaleCountryName(country) : undefined,
    };
  };

  const fetchBoutique = async (boutiqueId: string) => {
    const query = {
      query: {
        _id: boutiqueId,
      },
    };

    try {
      const response = called
        ? await refetch(query)
        : await execute({
            variables: query,
          });

      return formatData(response.data);
    } catch (error) {
      console.error('Error fetching boutique', error);
      return undefined;
    }
  };

  return {
    fetchBoutique,
    loading,
    error,
    isNotFound: isNotFound.current,
    boutique: formatData(data),
  };
}

export default useFetchBoutique;
