import { UserPermission } from '../enums/UserPermission';
import { UserRole } from '../enums/UserRole';

export default function (role: UserRole): UserPermission[] {
  switch (role) {
    case UserRole.SUPPORT:
      return [UserPermission.USERS_MANAGE, UserPermission.BLOG_READ];
    case UserRole.ADMIN:
      return [
        // TODO split crud ?
        UserPermission.USERS_MANAGE,

        UserPermission.REPORTING,

        UserPermission.BLOG_READ,
        UserPermission.BLOG_CREATE,
        UserPermission.BLOG_UPDATE,
        UserPermission.BLOG_DELETE,

        UserPermission.BOUTIQUE_UPDATE,
        UserPermission.BOUTIQUE_DELETE,

        UserPermission.BOUTIQUES_LISTING,

        UserPermission.BOUTIQUE_WIDGET_CREATE,
        UserPermission.BOUTIQUE_WIDGET_UPDATE,
        UserPermission.BOUTIQUE_WIDGET_DELETE,

        UserPermission.BOUTIQUE_GUEST_READ,
        UserPermission.BOUTIQUE_GUEST_CREATE,
        UserPermission.BOUTIQUE_GUEST_UPDATE,
        UserPermission.BOUTIQUE_GUEST_DELETE,

        UserPermission.BOUTIQUE_GUEST_EXPORT,

        UserPermission.BOOKING_UPDATE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_BOOKING_ASSIGN,
        UserPermission.BOOKING_CALENDAR_BOOKING_CREATE,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,
        UserPermission.BOOKING_SETTINGS_UPDATE_DISALLOW_GUEST_CHANGE_BOOKING,
        UserPermission.BOOKING_SETTINGS_UPDATE_DISALLOW_GUEST_CANCEL_BOOKING,
        UserPermission.BOOKING_SETTINGS_UPDATE_ALLOW_QR_CODE,
        UserPermission.BOOKING_SETTINGS_UPDATE_ENABLE_GOOGLE_WALLET,

        UserPermission.BOOKING_CATEGORIES_READ,
        UserPermission.BOOKING_CATEGORIES_CREATE,
        UserPermission.BOOKING_CATEGORIES_UPDATE,
        UserPermission.BOOKING_CATEGORIES_DELETE,
        UserPermission.BOOKING_SETTINGS_MANAGE_LANGUAGES,
        UserPermission.BOOKING_SETTINGS_ADVANCED_SCHEDULING,
        UserPermission.BOOKING_SETTINGS_ADVANCED_FEATURES,

        UserPermission.BOOKING_TEMPLATE_READ,
        UserPermission.BOOKING_TEMPLATE_UPDATE,

        UserPermission.BOOKING_STAFF_READ,
        UserPermission.BOOKING_STAFF_UPDATE,
      ];
    case UserRole.AFFILIATE_ADMIN:
      return [
        UserPermission.BLOG_READ,

        UserPermission.BOUTIQUE_UPDATE,
        UserPermission.BOUTIQUE_DELETE,

        UserPermission.BOUTIQUES_LISTING,

        UserPermission.BOUTIQUE_WIDGET_CREATE,
        UserPermission.BOUTIQUE_WIDGET_UPDATE,
        UserPermission.BOUTIQUE_WIDGET_DELETE,

        UserPermission.BOUTIQUE_GUEST_READ,
        UserPermission.BOUTIQUE_GUEST_CREATE,
        UserPermission.BOUTIQUE_GUEST_UPDATE,
        UserPermission.BOUTIQUE_GUEST_DELETE,

        UserPermission.BOUTIQUE_GUEST_EXPORT,

        UserPermission.BOOKING_UPDATE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_BOOKING_ASSIGN,
        UserPermission.BOOKING_CALENDAR_BOOKING_CREATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,

        UserPermission.BOOKING_TEMPLATE_READ,
        UserPermission.BOOKING_TEMPLATE_UPDATE,

        UserPermission.BOOKING_CATEGORIES_READ,
        UserPermission.BOOKING_CATEGORIES_CREATE,
        UserPermission.BOOKING_CATEGORIES_UPDATE,
        UserPermission.BOOKING_CATEGORIES_DELETE,

        UserPermission.BOOKING_STAFF_READ,
        UserPermission.BOOKING_STAFF_UPDATE,
      ];
    case UserRole.COLLABORATOR_HQ:
      return [
        UserPermission.BLOG_READ,

        UserPermission.BOUTIQUE_UPDATE,

        UserPermission.BOUTIQUES_LISTING,

        UserPermission.BOUTIQUE_WIDGET_CREATE,
        UserPermission.BOUTIQUE_WIDGET_UPDATE,
        UserPermission.BOUTIQUE_WIDGET_DELETE,

        UserPermission.BOUTIQUE_GUEST_READ,
        UserPermission.BOUTIQUE_GUEST_CREATE,
        UserPermission.BOUTIQUE_GUEST_UPDATE,
        UserPermission.BOUTIQUE_GUEST_DELETE,

        UserPermission.BOOKING_UPDATE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_BOOKING_ASSIGN,
        UserPermission.BOOKING_CALENDAR_BOOKING_CREATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,

        UserPermission.BOOKING_TEMPLATE_READ,
        UserPermission.BOOKING_TEMPLATE_UPDATE,

        UserPermission.BOOKING_CATEGORIES_READ,
        UserPermission.BOOKING_CATEGORIES_CREATE,
        UserPermission.BOOKING_CATEGORIES_UPDATE,
        UserPermission.BOOKING_CATEGORIES_DELETE,

        UserPermission.BOOKING_STAFF_READ,
        UserPermission.BOOKING_STAFF_UPDATE,
      ];
    case UserRole.COLLABORATOR:
      return [
        UserPermission.BLOG_READ,

        UserPermission.BOUTIQUE_UPDATE,

        UserPermission.BOUTIQUE_WIDGET_CREATE,
        UserPermission.BOUTIQUE_WIDGET_UPDATE,
        UserPermission.BOUTIQUE_WIDGET_DELETE,

        UserPermission.BOUTIQUE_GUEST_READ,
        UserPermission.BOUTIQUE_GUEST_CREATE,
        UserPermission.BOUTIQUE_GUEST_UPDATE,
        UserPermission.BOUTIQUE_GUEST_DELETE,

        UserPermission.BOOKING_UPDATE,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,

        UserPermission.BOOKING_TEMPLATE_READ,
        UserPermission.BOOKING_TEMPLATE_UPDATE,

        UserPermission.BOOKING_CATEGORIES_READ,
        UserPermission.BOOKING_CATEGORIES_CREATE,
        UserPermission.BOOKING_CATEGORIES_UPDATE,
        UserPermission.BOOKING_CATEGORIES_DELETE,
      ];
    case UserRole.SALES_MANAGER:
      return [
        UserPermission.BLOG_READ,

        UserPermission.BOUTIQUE_UPDATE,

        UserPermission.BOUTIQUES_LISTING,

        UserPermission.BOUTIQUE_WIDGET_CREATE,
        UserPermission.BOUTIQUE_WIDGET_UPDATE,
        UserPermission.BOUTIQUE_WIDGET_DELETE,

        UserPermission.BOUTIQUE_GUEST_READ,
        UserPermission.BOUTIQUE_GUEST_CREATE,
        UserPermission.BOUTIQUE_GUEST_UPDATE,
        UserPermission.BOUTIQUE_GUEST_DELETE,

        UserPermission.BOOKING_UPDATE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_BOOKING_ASSIGN,
        UserPermission.BOOKING_CALENDAR_BOOKING_CREATE,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,
        UserPermission.BOOKING_CALENDAR_OWN_OUTLOOK_EVENT_UPSERT,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,

        UserPermission.BOOKING_CATEGORIES_READ,
        UserPermission.BOOKING_CATEGORIES_CREATE,
        UserPermission.BOOKING_CATEGORIES_UPDATE,
        UserPermission.BOOKING_CATEGORIES_DELETE,

        UserPermission.BOOKING_STAFF_READ,
        UserPermission.BOOKING_STAFF_UPDATE,
      ];
    case UserRole.SALES_ASSOCIATE:
      return [
        UserPermission.BLOG_READ,

        UserPermission.BOUTIQUES_LISTING,

        UserPermission.BOUTIQUE_GUEST_READ,
        UserPermission.BOUTIQUE_GUEST_CREATE,
        UserPermission.BOUTIQUE_GUEST_UPDATE,
        UserPermission.BOUTIQUE_GUEST_DELETE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_BOOKING_ASSIGN,
        UserPermission.BOOKING_CALENDAR_BOOKING_CREATE,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,
        UserPermission.BOOKING_CALENDAR_OWN_OUTLOOK_EVENT_UPSERT,

        UserPermission.BOOKING_CATEGORIES_READ,

        UserPermission.BOOKING_STAFF_READ,
      ];
    case UserRole.WELCOME_HOST:
      return [
        UserPermission.BLOG_READ,

        UserPermission.BOUTIQUES_LISTING,

        UserPermission.BOUTIQUE_GUEST_READ,
        UserPermission.BOUTIQUE_GUEST_CREATE,
        UserPermission.BOUTIQUE_GUEST_UPDATE,
        UserPermission.BOUTIQUE_GUEST_DELETE,

        UserPermission.BOOKING_UPDATE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_BOOKING_ASSIGN,
        UserPermission.BOOKING_CALENDAR_BOOKING_CREATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,
        UserPermission.BOOKING_CALENDAR_OWN_OUTLOOK_EVENT_UPSERT,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,

        UserPermission.BOOKING_CATEGORIES_READ,
        UserPermission.BOOKING_CATEGORIES_CREATE,
        UserPermission.BOOKING_CATEGORIES_UPDATE,
        UserPermission.BOOKING_CATEGORIES_DELETE,

        UserPermission.BOOKING_STAFF_READ,
        UserPermission.BOOKING_STAFF_UPDATE,
      ];
    default:
      return [];
  }
}
