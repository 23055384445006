import { RouteObject } from 'react-router';
import { useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';
import blogRoutes from '../../features/blog/router/routes';
import boutiquesRoutes from '../../features/boutiques/router/routes';
import errorRoutes from '../../features/errors/router/routes';
import reportingRoutes from '../../features/reporting/router/routes';
import useCurrentUserCan from '../../features/users/services/useCurrentUserCan';
import usersRoutes from '../../features/users/router/routes';
import RootRedirect from './RootRedirect';

const useSentryRoutes = wrapUseRoutes(useRoutes);

function AppRouter() {
  const userCan = useCurrentUserCan();

  const routes: RouteObject[] = [
    {
      path: '/',
      element: <RootRedirect />,
    },
    ...boutiquesRoutes(userCan),
    ...reportingRoutes(userCan),
    ...blogRoutes(userCan),
    ...usersRoutes(userCan),
    ...errorRoutes,
  ];

  return useSentryRoutes(routes);
}

export default AppRouter;
