import { useMutation } from '@apollo/client';
import { UPDATE_BOOKING_SETTINGS } from './query';
import BookingSetting, { BookingSettingInsertInput, BookingSettingQueryInput } from '../models/BookingSetting';
import useLogEvent, { LogLevel } from '../../../helper/useLogEvent';
import useFetchBookingSettings from './useFetchBookingSettings';

interface MutationData {
  result: BookingSetting;
}

interface MutationVariables {
  query?: Pick<BookingSettingQueryInput, '_id'>;
  data: BookingSettingInsertInput;
}

/**
 * Update a booking's settings
 *
 * @param {string} bookingId ID of the current booking (NB: we cannot relly on BoutiqueContext here, because it can be undefined if this function is called directly from the BoutiqueContext component)
 */
function useSaveBookingSettings(bookingId: string) {
  const { logEvent } = useLogEvent();

  const { fetchBookingSettings } = useFetchBookingSettings();

  const [execute, { data, loading, error, client }] = useMutation<MutationData, MutationVariables>(
    UPDATE_BOOKING_SETTINGS,
  );

  /**
   * Replace all settings
   *
   * @param settings
   */
  const saveSettings = async (settings: BookingSetting) => {
    const currentSettings: BookingSettingInsertInput = {
      ...settings,
      bookingId: {
        link: bookingId,
      },
    };

    const response = await execute({
      variables: {
        query: {
          _id: currentSettings._id,
        },
        data: currentSettings,
      },
    });

    await client.refetchQueries({
      updateCache(cache) {
        cache.evict({ fieldName: 'bookingSetting' });
        cache.evict({ fieldName: 'bookingTemplates' });
      },
      optimistic: true,
    });

    logEvent(LogLevel.Info, 'Save booking settings', {
      id: response.data?.result?._id,
    });

    return response.data?.result?._id;
  };

  /**
   * Replace a specific settings
   *
   * @param settingsToUpdate
   */
  const saveSetting = async (settingsToUpdate: Partial<BookingSetting>) => {
    const settings = await fetchBookingSettings(bookingId);
    if (!settings) {
      return;
    }

    const newSettings: BookingSetting = {
      ...settings,
      ...settingsToUpdate,
    };

    await saveSettings(newSettings);

    return newSettings;
  };

  return { saveSettings, saveSetting, loading, error, id: data?.result?._id };
}

export default useSaveBookingSettings;
