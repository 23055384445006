import { gql } from '@apollo/client';

export const GET_MASTER_DATA_BOUTIQUES = gql`
  query masterDataBoutiques(
    $query: MasterDataBoutiqueQueryInput
    $limit: Int = 0
    $sortBy: MasterDataBoutiqueSortByInput
  ) {
    masterDataBoutiques(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      address {
        countryCode
      }
      boutiqueId
      name
      type
    }
  }
`;

export const GET_BOUTIQUES = gql`
  query findBoutiquesQuery($input: FindBoutiquesQueryResolverInput!) {
    result: findBoutiquesQuery(input: $input) {
      data {
        _id
        name
        createdBy
        country: countryId {
          _id
        }
        users {
          externalUserId
          type
        }
        boutique: boutiqueId {
          address {
            countryCode
          }
          name
          type
        }
        coverImage {
          urlRegular
        }
      }
      pagination {
        currentPage
        itemsPerPage
        totalPages
        totalItems
      }
    }
  }
`;

export const GET_BOUTIQUE = gql`
  query boutique($query: BoutiqueQueryInput) {
    boutique(query: $query) {
      _id
      name
      createdAt
      createdBy
      boutique: boutiqueId {
        _id
        name
        boutiqueId
        timezone
      }
      country: countryId {
        _id
        translations {
          _id
          name
        }
      }
      users {
        externalUserId
        displayName
        type
      }
      language {
        _id
        name
      }
      userLanguage {
        _id
        name
      }
      coverImage {
        unsplashId
        unsplashLink
        unsplashUserName
        unsplashUserUrl
        urlFull
        urlRegular
      }
      widgets {
        _id
        content {
          type
        }
        layouts {
          desktop {
            height
            positionX
            positionY
            preCollapseHeight
            width
          }
          mobile {
            height
            positionX
            positionY
            preCollapseHeight
            width
          }
        }
        movable
        resizable
        title
      }
    }
  }
`;

export const CREATE_OR_UPDATE_BOUTIQUE = gql`
  mutation upsertOneBoutique($query: BoutiqueQueryInput!, $data: BoutiqueInsertInput!) {
    result: upsertOneBoutique(query: $query, data: $data) {
      _id
    }
  }
`;

export const DELETE_BOUTIQUE = gql`
  mutation deleteOneBoutique($query: BoutiqueQueryInput!) {
    deleteOneBoutique(query: $query) {
      _id
    }
  }
`;

export const UPDATE_BOUTIQUE_WIDGETS = gql`
  mutation updateOneBoutique($query: BoutiqueQueryInput!, $set: BoutiqueUpdateInput!) {
    result: updateOneBoutique(query: $query, set: $set) {
      _id
    }
  }
`;
