import { useContext, useEffect } from 'react';
import { LayoutContext } from '../../template/components/Layout/Layout';
import { URI_BOUTIQUES, URI_BOUTIQUE } from '../../features/boutiques/router/uri';
import LoadingSpinner from '../../template/components/LoadingSpinner/LoadingSpinner';

function RootRedirect() {
  const { boutique } = useContext(LayoutContext);

  useEffect(() => {
    const path = !boutique || boutique === 'all' ? `/${URI_BOUTIQUES}` : `/${URI_BOUTIQUE}/${boutique}`;

    window.location.replace(path);
  }, [boutique]);

  return <LoadingSpinner fullPage />;
}

export default RootRedirect;
