import { useContext } from 'react';
import useNestClient from './useNestClient';
import { BoutiqueContext } from '../features/boutiques/pages/Boutique';

const disableLogs = import.meta.env.VITE_DISABLE_LOGS === 'true';

export enum LogLevel {
  Info = 'info',
  Notice = 'notice',
  Warning = 'warning',
  Error = 'error',
  Critical = 'crit',
  Alert = 'alert',
  Emergency = 'emerg',
}

interface Data {
  [key: string]: any;
}

function useLogEvent() {
  const { nestClient } = useNestClient();
  const { boutique } = useContext(BoutiqueContext);

  /**
   * Save Event log
   *
   * @param {LogLevel} level Log level
   * @param {string} message
   * @param {any} data additional object data
   */
  const logEvent = async (level: LogLevel, message: string, data?: Data) => {
    if (disableLogs) {
      return;
    }

    const boutiqueId = boutique._id ?? undefined;

    try {
      await nestClient.post('logs', { level, message, boutiqueId, ...data });
    } catch (error) {
      console.error('Cannot send log event', error);
    }
  };

  return { logEvent };
}

export default useLogEvent;
