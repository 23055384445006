import { useLazyQuery } from '@apollo/client';
import { GET_BOUTIQUES } from './query';
import Boutique from '../../models/Boutique';
import Pagination, { defaultPagination } from '../../../../models/Pagination';
import BaseQueryInputModel from '../../../../models/BaseQueryInputModel';

interface FindBoutiquesInput extends BaseQueryInputModel {
  limit: number;
  offset: number;
  sortBy: string;
  sortDirection: string;
  searchText?: string;
  countries?: string[];
}

interface QueryData {
  result: {
    data: Boutique[];
    pagination: Pagination;
  };
}

interface QueryVars {
  input: FindBoutiquesInput;
}

export interface PaginatedBoutiques {
  boutiques: Boutique[];
  pagination: Pagination;
}

/**
 * Fetch paginated list of boutiques
 */
function useFetchBoutiques() {
  const [execute, { loading, error, data, refetch, called, client }] = useLazyQuery<QueryData, QueryVars>(
    GET_BOUTIQUES,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const fetchBoutiques = async (params: FindBoutiquesInput): Promise<PaginatedBoutiques> => {
    const query: QueryVars = {
      input: {
        _partition: 'MASTER',
        ...params,
      },
    };

    if (!called) {
      // TODO GMB see if it's necessary to evict the cache (instead of returned the updated fields from the query)
      await client.refetchQueries({
        updateCache(cache) {
          cache.evict({ fieldName: 'findBoutiquesQuery' });
        },
      });
    }

    const response = called
      ? await refetch(query)
      : await execute({
          variables: query,
        });

    return {
      boutiques: response.data?.result.data ?? [],
      pagination: response.data?.result.pagination ?? defaultPagination,
    };
  };

  return {
    fetchBoutiques,
    loading,
    error,
    boutiques: data?.result.data ?? [],
    pagination: data?.result.pagination ?? defaultPagination,
  };
}

export default useFetchBoutiques;
