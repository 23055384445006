import React, { useEffect, useRef, useState } from 'react';
import { AutocompleteRenderGroupParams } from '@mui/material';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { APAutocomplete, APChip, APFlag } from '@ap/design-system';
import { APFlagCountryCode } from '@ap/design-system/dist/components/APFlag/types';
import { BoutiqueType } from '../../models/MasterDataBoutique';
import useFetchBoutiques from '../../services/boutiques/useFetchBoutiques';
import Boutique from '../../models/Boutique';

const getGroupName = (key: string) => {
  switch (key) {
    case BoutiqueType.Boutique:
      return 'Boutiques';
    case BoutiqueType.House:
      return 'AP Houses';
    default:
      return key;
  }
};

const renderGroup = (params: AutocompleteRenderGroupParams) => (
  <li key={params.key}>
    <ListSubheader key={params.key} component='div' sx={{ top: '-8px' }}>
      {getGroupName(params.group)}
    </ListSubheader>
    <ul style={{ paddingLeft: '8px' }}>{params.children}</ul>
  </li>
);

interface Props {
  id: string;
  label?: string;
  noOptionsText?: string;
  typeToSearchText?: string;
  /**
   * The value of the autocomplete.
   * Can be an object of type Boutique, Boutique[] (if multiple), or a string (if so, it MUST match and _id property)
   */
  value?: Boutique | Boutique[] | string;
  setValue: (value?: Boutique | Boutique[]) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  helperText?: string;
  error?: boolean;
  required?: boolean;
  limit?: number;
  multiple?: boolean;
  disabled?: boolean;
}

function BoutiquePicker(props: Props) {
  const {
    id,
    label = 'Boutique',
    noOptionsText = 'No matching boutiques',
    typeToSearchText = 'Type to search...',
    multiple = false,
    value = multiple ? [] : undefined,
    setValue,
    onBlur,
    helperText,
    error = false,
    required = false,
    limit = 5,
    disabled = false,
  } = props;

  const allBoutiques = { _id: 'all', name: 'All boutiques' } as Boutique;

  const searchedRef = useRef(false);

  const defaultValue = multiple ? [] : null;
  const [valueTyped, setValueTyped] = useState<Boutique | Boutique[] | null>(defaultValue);

  const { fetchBoutiques, boutiques } = useFetchBoutiques();

  useEffect(() => {
    fetchBoutiques({
      limit: 1000,
      offset: 0,
      sortBy: 'name',
      sortDirection: 'asc',
    });
  }, []);

  useEffect(() => {
    const valueTypesId = valueTyped && '_id' in valueTyped ? valueTyped._id : undefined;
    if (typeof value === 'string' && value === valueTypesId) {
      return;
    }

    if (typeof value === 'string' && value === 'all') {
      setValueTyped(allBoutiques);
      return;
    }

    setValueTyped(
      typeof value === 'string' ? boutiques.find((item) => item._id === value) ?? defaultValue : value ?? defaultValue,
    );
  }, [value, boutiques]);

  return (
    <APAutocomplete<Boutique, typeof multiple, undefined, false>
      id={id}
      label={label}
      error={error}
      helperText={helperText}
      required={required}
      value={valueTyped}
      onChange={(event, newValue) => {
        searchedRef.current = true;
        setValue(newValue ?? undefined);
      }}
      onInputChange={() => (searchedRef.current = true)}
      onBlur={onBlur}
      disabled={disabled}
      freeSolo={false}
      multiple={multiple ? true : undefined}
      autoComplete={true}
      filterSelectedOptions={true}
      limitTags={limit}
      groupBy={(option) => option.boutique?.type ?? ''}
      renderGroup={renderGroup}
      // getOptionLabel is required, even if the label is handled by renderOption
      getOptionLabel={(option) => `${option.name.split(new RegExp('Boutique | House'))[1] ?? option.name ?? ''}`}
      noOptionsText={searchedRef.current ? noOptionsText : typeToSearchText}
      renderOption={(props, option) => (
        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {option?.boutique?.address?.countryCode && (
            <APFlag
              countryCode={option.boutique.address.countryCode.toLowerCase() as APFlagCountryCode}
              width={20}
              height={20}
              showTooltip={false}
            />
          )}
          <Typography variant='footnote'>
            {option.name.split(new RegExp('Boutique | House'))[1] ?? option.name ?? ''}
          </Typography>
        </Box>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...rest } = getTagProps({ index });

          return (
            <APChip
              {...rest}
              key={`${option._id}_${index}`}
              label={option.name.split(new RegExp('Boutique | House'))[1]}
            />
          );
        })
      }
      options={[allBoutiques, ...boutiques].sort((a, b) =>
        a.boutique && b.boutique ? a.boutique?.type.localeCompare(b.boutique?.type) : 0,
      )}
      fullWidth={true}
      width='100%'
    />
  );
}

export default BoutiquePicker;
